import "./detail.scss";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";

const Detail = () => {
  return (
    <div className="detail">
      <Sidebar />
      <div className="detailContainer">
        <Navbar />
        Detail
      </div>
    </div>
  );
}

export default Detail