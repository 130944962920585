import {  useState } from "react";
import "./loginForm.scss"; 
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';

function LoginForm({ Login, error}) {
    const [info, setInfo] = useState({firstname:"", password:""});
    const submitHandeler = e =>{
        e.preventDefault();

        Login(info);
    }

    return (
     <div className="loginForm">
        <div className="loginContainer">
            <div className="loginTop">
                <span>Welcome to</span>
                <span>Generic App</span>
            </div>
            <div className="loginBottom">
                <form onSubmit={submitHandeler}>
                    <div className="form-inner">
                        {(error != "", "profileRows") ? ( <div className="error">{error}</div> ) : ""}
                        <div className="form-group">
                            <label htmlFor="firstname"></label>
                            <input type="text" placeholder="Firstname" name="firstname" id="name" onChange={e => setInfo({...info, firstname: e.target.value})} value={info.firstname}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="password"></label>
                            <input type="password"  placeholder="Password" firstname="password" id="password" onChange={e => setInfo({...info, password: e.target.value})} value={info.password}/>
                        </div>
                    </div>
                <button type="submit" value="Submit" ><LockPersonOutlinedIcon className="icon"/> Login</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default LoginForm