import Home from "./pages/home/Home";
import New from "./pages/new/new";
import Detail from "./pages/detail/Detail";
import LoginForm from "./pages/login/LoginForm";
import Login from "./pages/login/Login";

// import {userRows} from "./components/table/datasource";

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   Routes,
//   BrowserRouter
// } from "react-router-dom";

function App() {


  return (
    <div className="App">
     
      <Login />

    </div>
  );
}

export default App;
