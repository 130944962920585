import React from 'react'
import "./navbar.scss"
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {Logout} from '../../pages/login/Login';
import LoginForm from '../../pages/login/LoginForm';

const Navbar = () => {
  return (
    <div className='navbar'>
      <div className="wrapper">
        <div className="item">
          <ul>
            <a href="/profile">
              <li>
                  <AccountCircleOutlinedIcon className="icon"/>
                  <span>Account</span>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navbar


