import "./plan.scss"
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';

const Plan = () => {
  return (
    <div className="plan">
      <Sidebar />
      <div className="planContainer">
        <Navbar />
        <div className="planGroup">
          <div className="planBox">
              <div className="planTitle">
                <span>Platinum</span>
              </div>
              <div className="planDescription">
                <span>Lizards are a widespread group of squamate reptiles, with over 6,000
                      species, ranging across all continents except Antarctica
                </span>
              </div>
              <hr/>
              <div className="planDetail">
                <div className="planCol">
                    <span>Plan price per month: 200$</span>
                </div>
                <div className="planCol">
                  <span>Plan price for 12 months: 1000$</span>
                </div>
                <div className="planCol">
                  <span><DiscountOutlinedIcon className="icon"/>Discount for 12 months: 10%</span>
                </div>
              </div>                  
          </div>  
          <div className="planBox">
              <div className="planTitle">
                <span>Gold</span>
              </div>
              <div className="planDescription">
                <span>Lizards are a widespread group of squamate reptiles, with over 6,000
                      species, ranging across all continents except Antarctica
                </span>
              </div>
              <hr/>
              <div className="planDetail">
                <div className="planCol">
                    <span>Plan price per month: 200$</span>
                </div>
                <div className="planCol">
                  <span>Plan price for 12 months: 1000$</span>
                </div>
                <div className="planCol">
                  <span>Discount for 12 months: 10%</span>
                </div>
              </div>                  
          </div>  
          <div className="planBox">
              <div className="planTitle">
                <span>Silver</span>
              </div>
              <div className="planDescription">
                <span>Lizards are a widespread group of squamate reptiles, with over 6,000
                      species, ranging across all continents except Antarctica
                </span>
              </div>
              <hr/>
              <div className="planDetail">
                <div className="planCol">
                    <span>Plan price per month: 200$</span>
                </div>
                <div className="planCol">
                  <span>Plan price for 12 months: 1000$</span>
                </div>
                <div className="planCol">
                  <span>Discount for 12 months: 10%</span>
                </div>
              </div>                  
          </div>  
        </div>    
      </div>              
    </div>
  )
}

export default Plan



