import "./new.scss"
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const New = () => {
  return (
    <div className="new">
            <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add new App</h1>
        </div>
        <div className="bottom">
          {/* <div className="left">
            left
          </div> */}
          <div className="right">
            <form action="">
              <div className="formInput">
                <label>App</label>
                 <input type="text" placeholder="App Name" />
              </div>
              <div className="formInput">
                <label>Plan</label>
                 <input type="text" placeholder="Gold" />
              </div>
              <div className="formInput">
                <label>Inbound Email Type</label>
                <select name="inboundEmailType" id="inboundEmailType">
                  <option value="classicEmail">Classic Email</option>
                  <option value="emailRoutingOnly">Email routing only</option>
                  <option value="noInboundEmail">No inbound email</option>
                </select>
              </div>
              <div className="formInput">
                <label>Inbound Default Address</label>
                 <input type="text" placeholder="xxx.xxx.xxx.xx" autocomplete="off" />
              </div>
              <div className="formInput">
                <label>Default Fqdn</label>
                 <input type="text" placeholder="www.app.tld" autocomplete="off" />
              </div>
              <div className="formInput">
                <label>Start Date</label>
                 <input type="date" placeholder="dd.mm.yyyy" autocomplete="off" />
              </div>
              <div className="formInput">
                <label>End Date</label>
                 <input type="date" placeholder="dd.mm.yyyy" autocomplete="off" />
              </div>
              <div className="formInput">
                <label>Status</label>
                <select name="status" id="status">
                  <option value="active">Active</option>
                  <option value="terminated">Terminated</option>
                </select>
              </div>
              <button><SendTimeExtensionIcon className="icon"/>Send</button>
            </form>
          </div>
        </div>


        {/* <TableContainer className="table-add" component={Paper}>
       <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell className="tableCell">Name</TableCell>
                <TableCell className="tableCell">Plan</TableCell>
                <TableCell className="tableCell">Inbound Email Type</TableCell>
                <TableCell className="tableCell">Inbound Default Address</TableCell>
                <TableCell className="tableCell">Default Fqdn</TableCell>
                <TableCell className="tableCell">Start Date</TableCell>
                <TableCell className="tableCell">End Date</TableCell>
                <TableCell className="tableCell">Status</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          
            <TableRow>
                <TableCell className="tableCell">
                  <div className="formInput">
                    <label>App</label>
                    <input type="text" placeholder="App Name" />
                  </div>
                </TableCell>
                <TableCell className="tableCell">
                  <div className="formInput">
                    <label>Plan</label>
                    <input type="text" placeholder="Gold" />
                  </div>
                </TableCell>
                <TableCell className="tableCell">
                  <label>Inbound Email Type</label>
                  <select name="inboundEmailType" id="inboundEmailType">
                    <option value="classicEmail">Classic Email</option>
                    <option value="emailRoutingOnly">Email routing only</option>
                    <option value="noInboundEmail">No inbound email</option>
                  </select>
                </TableCell>
                <TableCell className="tableCell">
                  <div className="formInput">
                    <label>Inbound Default Address</label>
                    <input type="text" placeholder="xxx.xxx.xxx.xx" autocomplete="off" />
                  </div>
                </TableCell>
                <TableCell className="tableCell">
                  <div className="formInput">
                    <label>Default Fqdn</label>
                    <input type="text" placeholder="www.app.tld" autocomplete="off" />
                  </div>
                </TableCell>
                <TableCell className="tableCell">
                  <div className="formInput">
                    <label>Start Date</label>
                    <input type="date" placeholder="dd.mm.yyyy" autocomplete="off" />
                  </div>
                </TableCell>
                <TableCell className="tableCell">
                  <div className="formInput">
                    <label>End Date</label>
                    <input type="date" placeholder="dd.mm.yyyy" autocomplete="off" />
                  </div>
                </TableCell>
                <TableCell className="tableCell status">
                    <div className="formInput">
                    <label>Status</label>
                    <select name="status" id="status">
                      <option value="active">Active</option>
                      <option value="terminated">Terminated</option>
                    </select>
                  </div>
                </TableCell>                
            </TableRow>
        </TableBody>
        </Table>
    </TableContainer> */}


      </div>
    </div>
  );
}

export default New