import LoginForm from "./LoginForm";
import Home from "../home/Home";
import {useState} from "react";
// import {adminUser} from "./loginData.js";
import {profileRows} from "../profile/ProfileDatabase"

const LoginBottom = () => {
    const profileRows = {
        firstname: "admin",
        password: "admin123"
      }
    
      const [firstname, setUser] = useState({firstname:""});
      const [error, setError] = useState("");
    
      const Login = info => {
        console.log(info);
    
        if (info.firstname == (profileRows).firstname && info.password == (profileRows).password){
          console.log("Logged in");
          setUser({
            firstname: info.firstname,
            });
        }
        else {
            console.log("Details do not match!");
            setError("Details do not match!");
        }
      }
    
const Logout = () =>{
    setUser({ firstname:""});
  }
  return (
    <div>
         {(firstname.firstname !="") ? (
        <div classfirstname="welcome">          
          {/* <h2>welcome, <span>user.userfirstname</span></h2>
          <button onClick={Logout}>Log out</button> */}
          <Home />
        </div>
      ) : (
        <LoginForm Login={Login} error={error}/>
      )}
    </div>
  )
}

export default LoginBottom