import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Detail from "./pages/detail/Detail";
import Home from "./pages/home/Home";
import New from "./pages/new/new";
import Profile from "./pages/profile/Profile";
import Plan from "./pages/plan/Plan";
import Ticket from "./pages/ticket/Ticket";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<App />} />
        <Route path="home" element={<Home />} />
        <Route path="detail" element={<Detail />}/>
        <Route path="new" element={<New />}/>
        <Route path="profile" element={<Profile />}/>
        <Route path="plan" element={<Plan />}/>
        {/* <Route path="ticket" element={<Ticket />}/> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
