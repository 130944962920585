import React from 'react'
import "./sidebar.scss"
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

const Sidebar = () => {
  return (
    <div className='sidebar'>
        <a href="/home">
            <div className='top'><span className="logo">Generic App</span></div>
        </a>
        <hr/>
        <div className="center">
            <ul>
                <a href="/home">
                    <li>                    
                    <ExtensionOutlinedIcon className="icon"/>
                    <span>Apps</span>                    
                    </li>
                </a>
                <a href="#">
                    <li>
                        <ConfirmationNumberOutlinedIcon className="icon"/>
                        <div className="counter">1</div>
                        <span>Customer Care</span>
                    </li>
                </a>
            </ul>
        </div>
    </div>
  )
}

export default Sidebar
