import "./datatable.scss";
import { DataGrid } from '@mui/x-data-grid';
import {userColumns , userRows} from "./datasource.js";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const Datatable = () => {
    const actionColumn = [
        { 
            field:"action", 
            headerName: "", 
            width: 120, 
            renderCell: () => {
                return (
                    <div className="cellAction">
                        <div className="viewButton"><EditIcon className="icon"/></div>
                        <div className="deleteButton"><DeleteForeverIcon className="icon"/></div>
                    </div>
                )
            }
        }
    ];
    return (
        <div className="datatable">
        <DataGrid
            rows={userRows}
            columns={actionColumn.concat(userColumns)}
            pageSize={5}
            rowsPerPageOptions={[20]}
            />
        </div>
    )
}

export default Datatable