import "./profile.scss";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
// import {profileRows, profileColumns} from "./ProfileDatabase.js"
// import { DataGrid } from '@mui/x-data-grid';
import Ticket from "../ticket/Ticket"
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';

const Profile = () => {
//   const actionColumn = [
//     { 
//         field:"action", 
//         headerName: "", 
//         width: 120, 
//         renderCell: () => {
//             return (
//                 <div className="cellAction">
//                     <div className="viewButton"><EditIcon className="icon"/></div>
//                     <div className="deleteButton"><DeleteForeverIcon className="icon"/></div>
//                 </div>
//             )
//         }
//     }
// ];
  return (
    <div className="profile">
    <Sidebar />
      <div className="profileContainer">
        <Navbar />
        <div className="profileBox">
          <div className="profileLeft">
            <form action="/action_page.php">
              <div className="item">
                <label for="firstname">First name:</label>
                <input type="text" id="firstname" name="firstname" value="Admin"/>
              </div>
              <div className="item">
                <label for="lastname">Last name:</label>
                <input type="text" id="lastname" name="lastname" value="Adminovici"/>
              </div>
              <div className="item">
                <label for="lname">Password:</label>
                <input type="text" id="password" name="password" value="Admin123"/>
              </div>
              <div className="item">
                <label for="lname">Company:</label>
                <input type="text" id="lname" name="lname" value="Api Srl"/>
              </div>
              <div className="item">
                <label for="lname">Phone:</label>
                <input type="text" id="lname" name="lname" value="+40327930343"/>
              </div>
              <button type="submit" value="Submit" ><UpgradeOutlinedIcon className="icon"/> Update Profile</button>
            </form> 
          </div>
          <div className="profileRight">
            <Ticket />
          </div>
        {/* <DataGrid
            rows={profileRows}
            columns={actionColumn.concat(profileColumns)}
            pageSize={1}
            rowsPerPageOptions={[1]}
            /> */}
        </div>        
      </div>
    </div>
  )
}

export default Profile