import "./ticket.scss"
import { useState } from "react";

const Ticket = () => {
    const [comment, setComment] = useState("");
    const [comments, setComments]= useState([]);
    const onClickHandeler = (e) => {
        setComments((comments) => [...comments, comment]);
    };
    const onChangeHandeler = (e) => {
        setComment(e.target.value);
    };
    return (
        <div className='ticket'>
        <div className="ticketContainer">
            <div className="profileBox">
                <div className="commentBox">
                    <div className="commentFlexbox">
                        <h3 className="ticketText">Create Ticket</h3>
                        
                        <textarea 
                            value={comment}
                            onChange={onChangeHandeler}
                            className="inputBox"
                        />
                        <button onClick={onClickHandeler} className="commentButton">Submit</button>
                    </div>
                    <div className="reapeaterBox">
                    {comments.map((text) => (
                    <div className="ticketRepeater">{text}</div>
                    ))}
                    </div>
                </div>
            </div>        
        </div>
        </div>
    )
}

export default Ticket