import { dividerClasses } from "@mui/material";

export const userColumns = [
    { 
        field: 'name', 
        headerName: 'Name', 
        width: 200, 
        renderCell: (params) => {
            return (
            <div className="details-link">
                <a href="/detail">
                    {params.row.name}
                </a>               
            </div>
            )
         } 
    },
    { 
        field: 'plan', 
        headerName: 'Plan', 
        width: 120,
        renderCell: (params) => {
            return (
            <div className="details-link">
                <a href="/plan">
                    {params.row.plan}
                </a>               
            </div>
            )
         } 
    },
    { 
        field: 'inboundEmailType', 
        headerName: 'Inbound Email Type', 
        width: 180,
    },
    {
        field: 'inboundDefaultAddress',
        headerName: 'Inbound Default Address',
        width: 200,
        sortable: false,
    },
    { 
        field: 'defaultFqdn', 
        headerName: 'Default Fqdn',
        width: 140, 
        sortable: false,
        renderCell: (params) => {
            return (
            <div className="staticList">
                {params.row.defaultFqdn}
            </div>
            )
         }
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        type: 'string',
        width: 140,
        renderCell: (params) => {
            return (
            <div className="staticList">
                {params.row.startDate}
            </div>
            )
        }
    },
    {
        field: 'endDate',
        headerName: 'End Date',
        type: 'string',
        width: 140,
        renderCell: (params) => {
            return (
            <div className="staticList">
                {params.row.endDate}
            </div>
            )
        }
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 140,
        renderCell: (params) => {
            return (
            <div className="cellWidthStatus">
                <span className={params.row.status}>{params.row.status}</span>
            </div>
            )
        }
    },
];

//temporary data
export const userRows = [
    {
    id: "23120",
    name:"Better Search Replace",
    plan:"Platinum",
    inboundEmailType: "Classic Email",
    inboundDefaultAddress: "52.39.100.104",
    defaultFqdn: "www.app.tld",
    startDate: "23.Dec.2021",
    endDate: "26.Dec.2022",
    status: "active",
    },
    {
    id: "23121",
    name:"Under Construction",
    plan:"Silver",
    inboundEmailType: "Email routing only",
    inboundDefaultAddress: "52.39.100.104",
    defaultFqdn: "www.app.tld",
    startDate: "23.Dec.2021",
    endDate: "26.Dec.2022",
    status: "terminated",
    },
    {
    id: "23122",
    name:"Advanced Custom Fields",
    plan:"Platinum",
    inboundEmailType: "No inbound email",
    inboundDefaultAddress: "52.39.100.104",
    defaultFqdn: "www.app.tld",
    startDate: "23.Dec.2021",
    endDate: "26.Dec.2022",
    status: "terminated",
    },
    {
    id:"23123",
    name:"SVG Support",
    plan:"Gold",
    inboundEmailType: "Classic Email",
    inboundDefaultAddress: "52.39.100.104",
    defaultFqdn: "www.app.tld",
    startDate: "23.Dec.2021",
    endDate: "26.Dec.2022",
    status: "terminated",
    },
    {
    id: "2312",
    name:"Better Search Replace",
    plan:"Platinum",
    inboundEmailType: "Classic Email",
    inboundDefaultAddress: "52.39.100.104",
    defaultFqdn: "www.app.tld",
    startDate: "23.Dec.2021",
    endDate: "26.Dec.2022",
    status: "active",
    },
    {
    id: "2312",
    name:"Under Construction",
    plan:"Silver",
    inboundEmailType: "Email routing only",
    inboundDefaultAddress: "52.39.100.104",
    defaultFqdn: "www.app.tld",
    startDate: "23.Dec.2021",
    endDate: "26.Dec.2022",
    status: "terminated",
    },
    {
    id: "23142",
    name:"Advanced Custom Fields",
    plan:"Platinum",
    inboundEmailType: "No inbound email",
    inboundDefaultAddress: "52.39.100.104",
    defaultFqdn: "www.app.tld",
    startDate: "23.Dec.2021",
    endDate: "26.Dec.2022",
    status: "terminated",
    },
    {
    id:"2352",
    name:"SVG Support",
    plan:"Gold",
    inboundEmailType: "Classic Email",
    inboundDefaultAddress: "52.39.100.104",
    defaultFqdn: "www.app.tld",
    startDate: "23.Dec.2021",
    endDate: "26.Dec.2022",
    status: "terminated",
    },    
    {
        id: "2341",
        name:"Better Search Replace",
        plan:"Platinum",
        inboundEmailType: "Classic Email",
        inboundDefaultAddress: "52.39.100.104",
        defaultFqdn: "www.app.tld",
        startDate: "23.Dec.2021",
        endDate: "26.Dec.2022",
        status: "active",
        },
        {
        id: "2731",
        name:"Under Construction",
        plan:"Silver",
        inboundEmailType: "Email routing only",
        inboundDefaultAddress: "52.39.100.104",
        defaultFqdn: "www.app.tld",
        startDate: "23.Dec.2021",
        endDate: "26.Dec.2022",
        status: "terminated",
        },
        {
        id: "231722",
        name:"Advanced Custom Fields",
        plan:"Platinum",
        inboundEmailType: "No inbound email",
        inboundDefaultAddress: "52.39.100.104",
        defaultFqdn: "www.app.tld",
        startDate: "23.Dec.2021",
        endDate: "26.Dec.2022",
        status: "terminated",
        },
        {
        id:"231237",
        name:"SVG Support",
        plan:"Gold",
        inboundEmailType: "Classic Email",
        inboundDefaultAddress: "52.39.100.104",
        defaultFqdn: "www.app.tld",
        startDate: "23.Dec.2021",
        endDate: "26.Dec.2022",
        status: "terminated",
        },
        {
        id: "7231",
        name:"Better Search Replace",
        plan:"Platinum",
        inboundEmailType: "Classic Email",
        inboundDefaultAddress: "52.39.100.104",
        defaultFqdn: "www.app.tld",
        startDate: "23.Dec.2021",
        endDate: "26.Dec.2022",
        status: "active",
        },
        {
        id: "2833",
        name:"Under Construction",
        plan:"Silver",
        inboundEmailType: "Email routing only",
        inboundDefaultAddress: "52.39.100.104",
        defaultFqdn: "www.app.tld",
        startDate: "23.Dec.2021",
        endDate: "26.Dec.2022",
        status: "terminated",
        },
        {
        id: "2348",
        name:"Advanced Custom Fields",
        plan:"Platinum",
        inboundEmailType: "No inbound email",
        inboundDefaultAddress: "52.39.100.104",
        defaultFqdn: "www.app.tld",
        startDate: "23.Dec.2021",
        endDate: "26.Dec.2022",
        status: "terminated",
        },
        {
        id:"2358",
        name:"SVG Support",
        plan:"Gold",
        inboundEmailType: "Classic Email",
        inboundDefaultAddress: "52.39.100.104",
        defaultFqdn: "www.app.tld",
        startDate: "23.Dec.2021",
        endDate: "26.Dec.2022",
        status: "terminated",
        },
];